<template>
  <div class="">
    <a-upload
      name="avatar"
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      :before-upload="beforeUpload"
      :customRequest="uploadFiles"
      @change="handleChange"
      style=""
    >
      <img
        v-if="avatar"
        :src="avatar"
        alt="avatar"
        @error="$utils.imageOnerror($event)"
      />
      <div v-else>
        <a-icon :type="pic_url_loading ? 'loading' : 'plus'" />
      </div>
    </a-upload>
  </div>

</template>
<script>
import axios from 'axios'
export default {
  props: {
    baseUrl: {
      type: String,
      default: process.env.VUE_APP_API_BASE_URL + '/upload',
    },
    avatar: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      pic_url_loading: false,
      inavatar: this.avatar,
    }
  },
  methods: {
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    async uploadFiles(info) {
      let uploadApiUrl = this.baseUrl
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.inavatar = res.data.url
        this.$emit('sendurl', res.data.url)
      } else {
        this.$message.error(res.error.message)
      }


    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.pic_url_loading = true
        this.$emit('changeLoading', true)
        return;
      }
      if (info.file.status === 'done') {
        const { url } = info.file.response.data
        this.inavatar = url
        this.pic_url_loading = false
        this.$emit('changeLoading', false)
        this.$emit('sendurl', url)
      }
    },
  },
  created() {
    console.log(this.baseUrl, this.pic_url_loading, this.avatar);
  },
  mounted() {
    console.log(this.baseUrl, this.pic_url_loading, this.avatar, 1);
  },

}
</script>
<style lang="less" scoped>
</style>