// 数据colom
export default {
  permission: {
    columns: [
      {
        title: 'ID',
        dataIndex: 'permission_id',
        key: '_0',
        ellipsis: true,
        width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '权限名称',
        dataIndex: 'permission_name',
        key: '_2',
        ellipsis: true,
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '权限图标',
        dataIndex: 'permission_icon',
        key: '_3',
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {//todo
        title: '类型',
        dataIndex: 'permission_type',
        key: '_4',
        ellipsis: true,
        customRender: (name, record) => {
          return record.permission_type == 1 ? '菜单' : '权限'
        },
        scopedSlots: { customRender: '_4' },
      },
      {
        title: '权限路由',
        dataIndex: 'permission_route',
        key: '_5',
        ellipsis: true,
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '前端组件',
        dataIndex: 'permission_component',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },




      // {
      //   title: '权限对应组件',
      //   dataIndex: 'permission_component',
      //   key: '_6',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_6' },
      // },
      // {
      //   title: '权限参数',
      //   dataIndex: 'permission_params',
      //   key: '_8',
      //   ellipsis: true,
      //   scopedSlots: { customRender: '_8' },
      // },
      // {
      //   title: '权限类型',
      //   dataIndex: 'permission_params',
      //   key: '_9',
      //   ellipsis: true,
      //   customRender: (name, record) => {
      //     return record.permission_params == 1 ? '菜单' : '权限'
      //   },
      //   scopedSlots: { customRender: '_9' },
      // },
      {
        title: '权限状态',
        dataIndex: 'permission_is_show',
        key: '_10',
        ellipsis: true,
        scopedSlots: { customRender: '_10' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '220px',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  rules: {
    columns: [
      {
        title: 'ID',
        dataIndex: 'role_id',
        key: '_0',
        ellipsis: true,
        width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {//todo
        title: '角色名称',
        dataIndex: 'role_name',
        key: '_1',
        ellipsis: true,
        width: 120,
        customRender: (name, record) => {
          return (record.role_name || '--')
        },
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '权限数组',
        dataIndex: 'permission_list',
        key: '_2',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.permission_list || '--')
        },
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '状态',
        dataIndex: 'role_status',
        key: '_3',
        width: 120,
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 220,
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  list: {
    columns: [
      {
        title: 'ID',
        dataIndex: 'company_id',
        key: '_0',
        ellipsis: true,
        width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {//todo
        title: '公司代码',
        dataIndex: 'company_code',
        key: '_1',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.company_code || '--')
        },
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '公司全称/公司简称',
        dataIndex: 'company_name',
        key: '_2',
        ellipsis: true,
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '公司logo',
        dataIndex: 'company_logo',
        key: '_3',
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '联系人/手机号',
        dataIndex: 'company_boss_name',
        key: '_5',
        ellipsis: true,
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '上级公司',
        dataIndex: 'company_parent',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '公司当前级别',
        dataIndex: 'company_level',
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },
      },
      {
        title: '公司状态',
        dataIndex: 'company_status',
        key: '_9',
        ellipsis: true,
        customRender: (name, record) => {
          return record.company_status == 1 ? '审核中' : record.company_status == 2 ? '正常' : record.company_status == 3 ? '审核失败' : '已禁用'
        },
        scopedSlots: { customRender: '_9' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '220px',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  staff: {
    columns: [
      {
        title: 'ID',
        dataIndex: 'staff_id',
        key: '_0',
        ellipsis: true,
        width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '手机号',
        dataIndex: 'staff_mobile',
        key: '_1',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.staff_mobile || '--')
        },
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '姓名',
        dataIndex: 'staff_realname',
        key: '_2',
        ellipsis: true,
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '昵称',
        dataIndex: 'staff_nickname',
        key: '_3',
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '头像',
        dataIndex: 'staff_avatar',
        key: '_5',
        ellipsis: true,
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '紧急联系人',
        dataIndex: 'staff_contacts_one',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '可登陆的系统ID',
        dataIndex: 'staff_system_list',
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },
      },
      {
        title: '员工状态',
        dataIndex: 'staff_status',
        key: '_9',
        ellipsis: true,
        customRender: (name, record) => {
          return record.staff_status == 1 ? '在职' : "离职"
        },
        scopedSlots: { customRender: '_9' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '220px',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  message: {
    columns: [
      {
        title: 'ID',
        dataIndex: 'template_id',
        key: '_0',
        ellipsis: true,
        width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '模版名称',
        dataIndex: 'template_name',
        key: '_1',
        ellipsis: true,
        width: 180,
        customRender: (name, record) => {
          return (record.template_name || '--')
        },
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '模版code',
        dataIndex: 'template_code',
        key: '_4',
        ellipsis: true,
        width: 120,
        customRender: (name, record) => {
          return (record.template_code || '--')
        },
        scopedSlots: { customRender: '_4' },
      },
      {
        title: '短信模板说明',
        dataIndex: 'template_info',
        key: '_2',
        ellipsis: true,
        // width: '400px',
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '状态',
        dataIndex: 'template_status',
        key: '_3',
        ellipsis: true,
        width: 120,
        customRender: (name, record) => {
          return record.template_status == 1 ? '已激活' : "未激活"
        },
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '第三方短信模板ID',
        dataIndex: 'template_third_id',
        key: '_5',
        width: 180,
        ellipsis: true,
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '第三方短信模板内容参考',
        dataIndex: 'template_third_content',
        key: '_6',
        // width: '400px',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '130px',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
}